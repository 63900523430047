<template>
	<div>
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>下载Invoice</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card>
			<div class="title2">
				<span>Invoice列表，显示所有Invoice</span>
			</div>
			<el-row :gutter="20">
				<el-col :span="8">
					<el-date-picker v-model="dateRange" type="daterange" range-separator="至"
						start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels style="width: 100%"
						@change="timeChange">
					</el-date-picker>
				</el-col>
				<el-col :span="4">
					<el-button type="primary" @click="getInvoicesList" icon="el-icon-search">范围搜索</el-button>
				</el-col>
			</el-row>
			<el-table v-loading="loading" :data="invoicesList" stripe @sort-change="changeSort"
				style="width: 100%">
				<el-table-column type="index"></el-table-column>
				<el-table-column prop="invoice_date" label="签发日" sortable="custom" width="100"
					:formatter="formatDate">
				</el-table-column>
				<el-table-column prop="due_date" label="到期日" sortable="custom" width="100"
					:formatter="formatDate">
				</el-table-column>
				<el-table-column prop="invoice_type" label="Invoice名目" width="245">
				</el-table-column>
				<el-table-column prop="invoice_amount" label="总额" sortable="custom" min-width="100"
					:formatter="usdFormatter">
				</el-table-column>
				<el-table-column prop="note" label="备注" min-width="400">
				</el-table-column>
				<el-table-column prop="is_paid" label="付款" width="80" sortable="custom" fixed="right">
					<template slot-scope="scope">
						<el-tag v-if="scope.row.is_paid" type="success" size="medium">已付</el-tag>
						<el-tag v-else type="danger" size="medium">未付</el-tag>
					</template>
				</el-table-column>
				<el-table-column prop="dl_url" label="Invoice下载" width="100" fixed="right">
					<template slot-scope="scope">
						<span v-if="!scope.row.dl_url || scope.row.dl_url === 'N/A'">
							<el-badge v-if="!scope.row.is_read" value="new" class="item invoice">
								N/A
							</el-badge>
							<span v-else>N/A</span>
						</span>
						<el-link v-else type="primary" :href="scope.row.dl_url"
							@click.native="changeReadStatus(scope.row)">
							<el-badge v-if="!scope.row.is_read" value="new" class="item invoice">
								下载
							</el-badge>
							<span v-else>下载</span>
						</el-link>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="queryInfo.currentPage" :page-sizes="[10, 20, 50, 100]"
				:page-size="queryInfo.pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
import {mapMutations} from 'vuex';
export default {
	name: 'Invoice',
	data() {
		return {
			dateRange: null,
			queryInfo: {
				date_start: null,
				date_end: null,
				currentPage: 1,
				pageSize: 10,
				order: '',
				prop: ''
			},
			loading: false,
			invoicesList: [],
			total: 0
		};
	},
	created() {
		this.getInvoicesList();
	},
	methods: {
		...mapMutations(['changeUnreadCount']),
		async getInvoicesList() {
			this.loading = true;
			const {data: res} = await this.$http.get('clients/invoices/' + window.sessionStorage.getItem('cid'), {params: this.queryInfo});
			if (res.meta.status !== 200) return this.$message.error('获取Invoice列表失败！');
			this.invoicesList = res.data.invoices;
			this.total = res.data.total;
			this.loading = false;
		},
		timeChange(value) {
			if (!value) {
				this.queryInfo.date_start = null;
				this.queryInfo.date_end = null;
				this.queryInfo.currentPage = 1;
				this.getInvoicesList();
			} else {
				this.queryInfo.date_start = value[0];
				this.queryInfo.date_end = value[1];
			}
		},
		changeSort({order, prop}) {
			this.queryInfo.order = order;
			this.queryInfo.prop = prop;
			this.getInvoicesList();
		},
		formatDate(r, c, v) {
			if (v) return this.$dayjs(v).format('MM-DD-YYYY');
			return v;
		},
		handleSizeChange(newSize) {
			this.queryInfo.pageSize = newSize;
			this.getInvoicesList();
		},
		handleCurrentChange(newPage) {
			this.queryInfo.currentPage = newPage;
			this.getInvoicesList();
		},
		async changeReadStatus(invoice) {
			if (invoice.is_read) return false;
			const {data: res} = await this.$http.put(`client/changeUnreadStatus/${invoice.owner}/${invoice.id}`);
			this.changeUnreadCount(res.data);
			this.getInvoicesList();
		}
	}
}
</script>

<style lang="less" scoped>
.el-card {
	.title {
		font-size: 22px;
		line-height: 1.5;
		font-weight: 600;
		margin-bottom: 14px;
	}
	.subtitle {
		font-size: 14px;
		line-height: 20px;
	}
	.title2 {
		text-align: center;
		font-size: 16px;
		line-height: 24px;
		font-weight: 700;
		margin-bottom: 20px;
	}
	.el-col.header-last {
		text-align: right;
	}
	.el-table {
		margin-top: 20px;
	}
}
</style>